/*
 * @Author: mochuxiang
 * @Date: 2024-02-21 09:33:30
 * @LastEditors: mochuxiang
 * @LastEditTime: 2024-11-04 17:24:31
 * @Description: 
 */
import fetch from '@/utils/fetch'

export function login(query) {
  return fetch({
    url: '/operator/login',
    method: 'post',
    params: query
  })
}

export function getPermission(query) {
  return fetch({
    url: '/permission',
    method: 'get',
    params: query
  })
}

export function getUserInfo() {
  return fetch({
    url: '/operator/getinfo',
    method: 'get'
  })
}

export function refreshToken(params) {
  return fetch({
    url: '/token/refresh',
    method: 'get'
  })
}

export function getAgreementContent() {
  return fetch({
    url: '/espier/system/agreement',
    method: 'post'
  })
}
